'use client'
import { useSearchType } from '@/api/hooks'
import Loader from '@/app/components/loader'
import { SearchTypeCopy, SearchTypeToFetchType } from '@/app/util/search-util'
import { useGiphyFetch } from '@/app/util/use-gf'
import Grid from '@/components/grid/grid'
import VideoGrid from '@/components/grid/video-grid'
import { SearchTypeContext } from '@/context/search-type'
import UAParserContext from '@/context/ua-parser'
import { GifsResult } from '@giphy/js-fetch-api'
import { IGif } from '@giphy/js-types'
import { useSearchParams } from 'next/navigation'
import { useCallback, useContext, useRef } from 'react'

const getTrackingUrl = (page: number, term: string) => `/search/${term}/${page}`

type Props = {
    term: string
    initialGifs?: IGif[]
}
const SearchGrids = ({ term, initialGifs }: Props) => {
    const { current: initialSearchTerm } = useRef(term)
    const { isTransitioning } = useContext(SearchTypeContext)
    const type = useSearchType()
    const { isBot } = useContext(UAParserContext)
    const params = useSearchParams()
    const gf = useGiphyFetch()
    const fetchGifs = useCallback(
        async (offset: number) =>
            gf.search(initialSearchTerm, {
                offset,
                limit: 15,
                type: SearchTypeToFetchType[type],
            }),
        [gf, initialSearchTerm, type]
    )
    const fakeFetchGifs = () =>
        Promise.resolve({
            data: [],
            meta: { response_id: '', msg: 'OK', status: 404 },
            pagination: { total_count: 0, offset: 0, count: 0 },
        } as GifsResult)
    return isTransitioning ? (
        <Loader />
    ) : (
        <>
            {type === 'clips' ? (
                <VideoGrid
                    initialGifs={initialGifs}
                    fetchGifs={isBot ? fakeFetchGifs : fetchGifs}
                    key={`${term + type + params.toString()}`}
                    gaTrackingUrl={(page) => getTrackingUrl(page, initialSearchTerm)}
                />
            ) : (
                <Grid
                    initialGifs={initialGifs}
                    key={`${term + type + params.toString()}`}
                    fetchGifs={isBot ? fakeFetchGifs : fetchGifs}
                    gaTrackingUrl={(page) => getTrackingUrl(page, initialSearchTerm)}
                    noShowMessage={
                        <>
                            No {SearchTypeCopy[type]} found for {initialSearchTerm}.<br />
                            Try searching for {SearchTypeCopy[type === 'gifs' ? 'stickers' : 'gifs']} instead?
                        </>
                    }
                />
            )}
        </>
    )
}
export default SearchGrids
